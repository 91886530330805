/*
 * @Author: your name
 * @Date: 2022-04-13 16:47:00
 * @LastEditTime: 2022-04-13 16:47:03
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /seller-enter-web/src/api/accountStatement.js
 */

import server from '@/request';

const prefix = '/xiaozi-seller/wireless/reconciliation';

// 查询 采购对账 列表(根据条件)
export const queryReconciliationList = (params = {}) => {
  return server.post(`${prefix}/queryReconciliationList`, params);
};

// 统计 采购对账 状态信息
export const queryStateInfo = (params = {}) => {
  return server.post(`${prefix}/queryStateInfoBySupId`, params);
};

// 修改采购对账状态
export const editReconciliationState = (params = {}) => {
  return server.post(`${prefix}/editState`, params);
};

// 查询 采购对账 详情(根据对账ID)
export const getDetailReconciliation = (params = {}) => {
  return server.post(`${prefix}/queryById`, params);
};

// 查询 冲销明细 (根据对账明细 ID)
export const getDetailReconciliationLists = (params = {}) => {
  return server.post(`${prefix}/queryWriteOffListById`, params);
};

// 导出明细
export const exportTable = (params) => {
  return server.post(`${prefix}/excel`, params, {
    responseType: 'blob',
  });
};

// 获取操作日志列表
export const getOperationLogLists = (params) => {
  return server.post('/xiaozi-seller/wireless/purchLog/queryByPage', params);
};

// 新增日志（供应链中心）
export const AddOperationLog = (params) => {
  return server.post('/xiaozi-seller/wireless/purchLog/add', params);
};

// 查询 开票信息 (根据对账ID)
export const getReconciliationInvoiceList = (params) => {
  return server.post('/xiaozi-seller/wireless/reconciliation/reconciliationInvoiceList', params);
};

// 获取支付信息列表
export const getPaymentInformationLists = (params) => {
  return server.post('/xiaozi-seller/wireless/reconciliation/viewOrderPayByOrderId', params);
};

// 查询 冲销明细 (根据对账明细 ID)
export const getWriteOffInformationList = (params) => {
  return server.post('/xiaozi-seller/wireless/reconciliation/queryWriteOffInfoById', params);
};
