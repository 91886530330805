<!--
 * @Author: your name
 * @Date: 2022-04-08 16:37:19
 * @LastEditTime: 2022-04-08 16:37:25
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /seller-enter-web/src/views/salesReconciliation/accountStatement.vue
-->
<template>
  <div class="xz-contract">
    <query-form
      class="content_block"
      label-width="116px"
      size="small"
      label-position="right"
      @search="handleSearch('init')"
      @reset="handleReset"
      :keepOpenedRow="2"
    >
      <el-form-item label="" label-width="0px" :span="24">
        <check-tag v-model="JZForm.reconciliaState" @change="() => handleSearch()">
          <check-tag-item
            :value="i.value"
            :key="i.value"
            v-for="i in executionStatusGroup"
            :label="`${i.label}(${i.count || 0})`"
          ></check-tag-item>
        </check-tag>
      </el-form-item>
      <el-form-item label="对账单编号：" :span='8'>
        <el-input
          v-model.trim="JZForm.prCode"
          clearable
          placeholder="请输入对账单编号"
        ></el-input>
      </el-form-item>
      <el-form-item label="付款方式：" :span='8'>
        <el-select
          v-model.trim="JZForm.paymentWay"
          clearable
          @change="handleSearch"
          placeholder="请选择付款方式"
        >
          <el-option
            v-for="item in payWayOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="核销状态：" :span='8'>
        <el-select
          v-model.trim="JZForm.writeOffState"
          clearable
          @change="handleSearch"
          placeholder="请选择收款状态"
        >
          <el-option
            v-for="item in writeOffStateOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="开票状态：" :span='8'>
        <el-select
          v-model.trim="JZForm.opentTicketState"
          clearable
          @change="handleSearch"
          placeholder="请选择开票状态"
        >
          <el-option
            v-for="item in billingStatusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="入票状态：" :span='8'>
        <el-select
          v-model.trim="JZForm.inTicketState"
          clearable
          @change="handleSearch"
          placeholder="请选择入票状态"
        >
          <el-option
            v-for="item in ticketEntryStatusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="付款申请金额：" :span='8' class="amountInfo">
        <el-select
          v-model.trim="JZForm.mark"
          clearable
          placeholder="请选择"
          @change="handleSearch"
          style="width:140px;margin-right:10px;"
        >
          <el-option
            v-for="item in unitOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-input-number v-model.trim="JZForm.payRefundAmount" :precision="2" :step="1" :min="0" placeholder="请输入付款申请金额"></el-input-number>
      </el-form-item>
      <el-form-item label="客户抬头：" :span='8'>
        <el-input
          v-model.trim="JZForm.unitName"
          clearable
          placeholder="请输入客户抬头"
        ></el-input>
      </el-form-item>
      <el-form-item label="对账开始日期：" :span='8'>
        <el-date-picker
          v-model="JZForm.reconciliaStart"
          type="date"
          @change="handleSearch"
          value-format='yyyy-MM-dd'
          placeholder="请选择对账开始日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="对账结束日期：" :span='8'>
        <el-date-picker
          v-model="JZForm.reconciliaEnd"
          type="date"
          @change="handleSearch"
          value-format='yyyy-MM-dd'
          placeholder="请选择对账结束日期">
        </el-date-picker>
      </el-form-item>
    </query-form>
    <TableWithPagination
      class="content_block"
      :columnList="columnList"
      :tableData="dataList"
      :pagination.sync="pagination"
      @size-change="getTargetLists"
      @current-change="getTargetLists"
      @sort-change="getTargetLists"
    />
  </div>
</template>
<script>
import TableWithPagination, {
  TablePaginationMixin,
} from '@/components/common/TableWithPagination';
import { queryStateInfo, queryReconciliationList } from '@/api/accountStatement';
import {
  collectionStatusOptions,
  deliveryStatusOptions,
  changeStatusOptions,
} from '@/model/saleContract';

export default {
  name: 'AccountStatement',
  mixins: [TablePaginationMixin],
  components: {
    TableWithPagination,
  },
  data () {
    return {
      columnList: [
        {
          tooltip: true,
          label: '客户抬头',
          minWidth: '180px',
          prop: 'unitName',
        },
        {
          tooltip: true,
          label: '对账区间',
          minWidth: '210px',
          prop: 'reconciliaStart',
          render: (h, {row}) => {
            return h('span', `${row.reconciliaStart} - ${row.reconciliaEnd}`);
          },
        },
        {
          tooltip: true,
          label: '对账单编号',
          minWidth: '180px',
          prop: 'prCode',
          fixed: 'left',
          render: (h, {row}) => {
            let actions = [];
            actions.push(h('span', {
              domProps: {
                className: 'table-action',
              },
              on: {
                click: this.handleDetail.bind(this, row),
              },
            }, row.prCode));
            return h('div', actions);
          },
        },
        {
          tooltip: true,
          label: '对账状态',
          minWidth: '110px',
          prop: 'reconciliaState',
          render: (h, {row}) => {
            let statusStr = {
              'wait': '待供应商确认',
              'reject': '供应商驳回',
              'run': '结算中',
              'already': '已完成',
            }[row.reconciliaState];
            return h('span', statusStr);
          },
        },
        {
          tooltip: true,
          label: '对账金额(元)',
          minWidth: '110px',
          prop: 'reconciliaAmount',
        },
        {
          tooltip: true,
          label: '对账数量',
          minWidth: '110px',
          prop: 'reconciliaNum',
        },
        {
          tooltip: true,
          label: '付款方式',
          minWidth: '110px',
          prop: 'paymentWay',
          render: (h, {row}) => {
            let statusStr = {
              'AP': '账期付款',
            }[row.paymentWay];
            return h('span', statusStr);
          },
        },
        {
          tooltip: true,
          label: '开票状态',
          minWidth: '110px',
          prop: 'opentTicketState',
          render: (h, {row}) => {
            let statusStr = {
              'wait': '待开票',
              'part': '部分开票',
              'already': '已完成',
            }[row.opentTicketState];
            return h('span', statusStr);
          },
        },
        {
          tooltip: true,
          label: '入票状态',
          minWidth: '110px',
          prop: 'inTicketState',
          render: (h, {row}) => {
            let statusStr = {
              'wait': '待入票',
              'part': '部分入票',
              'already': '已入票',
            }[row.inTicketState];
            return h('span', statusStr);
          },
        },
        {
          tooltip: true,
          label: '核销状态',
          minWidth: '110px',
          prop: 'writeOffState',
          render: (h, {row}) => {
            let statusStr = {
              'wait': '待核销',
              'part': '部分核销',
              'already': '已核销',
            }[row.writeOffState];
            return h('span', statusStr);
          },
        },
        {
          tooltip: true,
          label: '创建时间',
          minWidth: '180px',
          prop: 'createTime',
        },
        {
          tooltip: true,
          label: '更新时间',
          minWidth: '180px',
          prop: 'lastUpdateTime',
        },
        {
          tooltip: true,
          label: '创建方式',
          minWidth: '110px',
          prop: 'createWay',
          render: (h, {row}) => {
            let statusStr = {
              'manual': '手动',
              'automate': '自动',
            }[row.createWay];
            return h('span', statusStr);
          },
        },
        {
          tooltip: true,
          label: '操作',
          minWidth: '100px',
          prop: 'id',
          fixed: 'right',
          render: (h, {row}) => {
            let actions = [];
            if (row.reconciliaState === 'wait') {
              actions.push(h('span', {
                domProps: {
                  className: 'table-action',
                },
                on: {
                  click: this.handleEnsure.bind(this, row),
                },
              }, '确认'));
            }
            return h('div', actions);
          },
        },
      ],
      dataList: [],
      payWayOptions: [
        {
          label: '全部',
          value: null,
        },
        {
          label: '账期付款',
          value: 'AP',
        },
      ],
      billingStatusOptions: [
        {
          label: '全部',
          value: null,
        },
        {
          label: '待开票',
          value: 'wait',
        },
        {
          label: '部分开票',
          value: 'part',
        },
        {
          label: '已开票',
          value: 'already',
        },
      ],
      ticketEntryStatusOptions: [
        {
          label: '全部',
          value: null,
        },
        {
          label: '待入票',
          value: 'wait',
        },
        {
          label: '部分入票',
          value: 'part',
        },
        {
          label: '已入票',
          value: 'already',
        },
      ],
      writeOffStateOptions: [
        {
          label: '全部',
          value: null,
        },
        {
          label: '待核销',
          value: 'wait',
        },
        {
          label: '部分核销',
          value: 'part',
        },
        {
          label: '已核销',
          value: 'already',
        },
      ],
      unitOptions: [
        {
          label: '>',
          value: 2,
        },
        {
          label: '<',
          value: 3,
        },
        {
          label: '=',
          value: 1,
        },
        {
          label: '>=',
          value: 4,
        },
        {
          label: '<=',
          value: 5,
        },
      ],
      executionStatusGroup: [
        {
          label: '全部',
          value: '',
          count: 0,
        },
        {
          label: '待供应商确认',
          value: 'wait',
          count: 0,
        },
        {
          label: '结算中',
          value: 'run',
          count: 0,
        },
        {
          label: '已完成',
          value: 'already',
          count: 0,
        },
        {
          label: '供应商驳回',
          value: 'reject',
          count: 0,
        },
      ],
      JZForm: {
        reconciliaState: '',
        mark: null,
        payRefundAmount: 0,
        unitName: '',
        paymentWay: null,
        writeOffState: null,
        inTicketState: null,
        opentTicketState: null,
        prCode: '',
        reconciliaStart: '',
        reconciliaEnd: '',
        operateTime: [],
      },
      collectionStatusOptions,
      deliveryStatusOptions,
      changeStatusOptions,
    };
  },
  created () {
    this.$store.dispatch('changeDefaultActive', this.$route.name);
    if (this.$route.query.status) {
      this.JZForm.reconciliaState = 'wait';
    }
    this.initPage();
  },
  methods: {
    initPage () {
      this.handleSearch();
      this.getSaleContractCount();
    },
    handleEnsure (info) {
      this.$router.push({
        name: 'DetailAccountStatement',
        query: {
          id: info.id,
          status: info.reconciliaState,
          isWait: '1',
        },
      });
    },
    handleDetail (info) {
      this.$router.push({
        name: 'DetailAccountStatement',
        query: {
          id: info.id,
          status: info.reconciliaState,
        },
      });
    },
    handleSearch () {
      this.pagination.currentPage = 1;
      this.getTargetLists();
    },
    getTargetLists () {
      let currentParams = {
        ...this.JZForm,
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        // reconciliaStart: this.JZForm.operateTime && this.JZForm.operateTime.length ? this.JZForm.operateTime[0] : '',
        // reconciliaEnd: this.JZForm.operateTime && this.JZForm.operateTime.length ? this.JZForm.operateTime[1] : '',
      };
      queryReconciliationList(currentParams).then(({body}) => {
        this.dataList = body.list;
        this.pagination.total = body.total || 0;
      });
    },
    getSaleContractCount () {
      queryStateInfo({}).then(({ body }) => {
        this.executionStatusGroup = [
          {
            label: '全部',
            value: '',
            count: body.allNum || 0,
          },
          {
            label: '待供应商确认',
            value: 'wait',
            count: body.waitNum || 0,
          },
          {
            label: '结算中',
            value: 'run',
            count: body.runnNum || 0,
          },
          {
            label: '已完成',
            value: 'already',
            count: body.alreadyNum || 0,
          },
          {
            label: '供应商驳回',
            value: 'reject',
            count: body.rejectNum || 0,
          },
        ];
      });
    },
    handleReset () {
      this.JZForm = {
        reconciliaState: '',
        mark: null,
        payRefundAmount: 0,
        unitName: '',
        paymentWay: null,
        writeOffState: null,
        inTicketState: null,
        opentTicketState: null,
        prCode: '',
        reconciliaStart: '',
        reconciliaEnd: '',
        operateTime: [],
      };
      this.handleSearch();
    },
  },
};
</script>
<style lang="scss" scoped>
.xz-contract {
  /deep/ .contract-option {
    color: #02a7f0;
    cursor: pointer;
    padding: 0 0.25em;
  }

  .el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 100%;
  }
}
/deep/ .amountInfo .el-form-item__content{
  display: flex;
}
/deep/ .content_block .el-col{
  height: 45px;
}
</style>
